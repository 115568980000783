<template>
  <div class="tooltip-container" @mouseover="showTooltip" @mouseleave="hideTooltip">
    <span class="tooltip-text" :class="[positionClass, {'visible': isTooltipVisible}]">{{ label }}</span>
    <div class="tip" :class="[tipPositionClass, {'visible': isTooltipVisible}]">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="8" viewBox="0 0 24 8" fill="none">
        <path
          fill="#333"
          d="M9.57564 6.87661L5.85294 2.7121C4.30064 0.97557 2.19528 0 0 0H24C21.8047 0 19.6994 0.975567 18.1471 2.71209L14.4244 6.87661C13.0854 8.37446 10.9146 8.37446 9.57564 6.87661Z"
        />
      </svg>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    position: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'right', 'bottom', 'left'].includes(value),
    },
  },
  data() {
    return {
      isTooltipVisible: false,
    };
  },
  methods: {
    showTooltip() {
      this.isTooltipVisible = true;
    },
    hideTooltip() {
      this.isTooltipVisible = false;
    },
  },
  computed: {
    positionClass() {
      return `tooltip-${this.position}`;
    },
    tipPositionClass() {
      return `tip-${this.position}`;
    }
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  transition: visibility 0.3s, opacity 0.3s ease; /* Add a smooth transition */
  
  bottom: 25px;
  right: 0px;
  width: 240px;

  /* en/Mobile/Body/XXS */
  font-family: JioType;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.06px;
}

.tip {
  visibility: hidden;
  position: absolute;
  transition: visibility 0.3s, opacity 0.3s ease; /* Add a smooth transition */
}

.tooltip-text.visible {
  visibility: visible;
}

.tip.visible {
  visibility: visible;
}

.tooltip-top {
  bottom: 125%;
  right: -25%;
}

.tip-top {
  bottom: 75%;
  right: 10%;
}

.tooltip-right {
  left: 125%;
  bottom: -125%;
}

.tip-right {
  transform: rotate(90deg) translateY(-60px);
}

.tooltip-left {
  right: 125%;
  bottom: -125%;
}

.tip-left {
  transform: rotate(-90deg) translateY(-25px);
}

</style>

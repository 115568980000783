<template>
  <svg
    :class="sizeClass"
    :viewBox="pointy ? '0 0 11 11' : '0 0 40 40'"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <defs v-if="this.kind === 'partial'">
      <linearGradient id="grad">
        <stop :offset="fillPercent + '%'" stop-color="#FFD947" />
        <stop :offset="fillPercent + '%'" stop-color="#E0E0E0" />
      </linearGradient>
    </defs>
    <g>
      <path
        :fill="fillColor"
        :d='pointy ? 
          "M5.5 0.5L6.72847 4.30915L10.7308 4.30041L7.48771 6.64585L8.73282 10.4496L5.5 8.09L2.26718 10.4496L3.51229 6.64585L0.269189 4.30041L4.27153 4.30915L5.5 0.5Z"
          :"M36.4992 15.8003C36.306 15.2155 35.9545 14.6957 35.4838 14.2985C35.0131 13.9013 34.4415 13.6423 33.8326 13.5503L26.3826 12.4169L23.0493 5.25026C22.7801 4.6776 22.3534 4.19339 21.8192 3.85423C21.2851 3.51507 20.6654 3.33496 20.0326 3.33496C19.3998 3.33496 18.7801 3.51507 18.2459 3.85423C17.7117 4.19339 17.2851 4.6776 17.0159 5.25026L13.6826 12.4169L6.16592 13.5503C5.55695 13.6423 4.98541 13.9013 4.51469 14.2985C4.04397 14.6957 3.69249 15.2155 3.49925 15.8003C3.31612 16.3748 3.29225 16.9882 3.43019 17.5752C3.56814 18.1622 3.86273 18.7008 4.28259 19.1336L9.76592 24.7669L8.46592 32.7503C8.36714 33.3656 8.44283 33.9963 8.6844 34.5707C8.92597 35.1452 9.32375 35.6405 9.83259 36.0003C10.3913 36.4196 11.0675 36.6528 11.7659 36.6669C12.3489 36.6809 12.9248 36.5369 13.4326 36.2503L20.0993 32.5836L26.7659 36.2503C27.2737 36.5369 27.8496 36.6809 28.4326 36.6669C29.1255 36.6673 29.8012 36.4518 30.3659 36.0503C30.8748 35.6904 31.2725 35.1952 31.5141 34.6207C31.7557 34.0463 31.8314 33.4156 31.7326 32.8003L30.4326 24.8169L35.9159 19.1836C36.3109 18.7256 36.5739 18.1688 36.6767 17.5728C36.7794 16.9768 36.7181 16.3641 36.4992 15.8003Z"'
      />
    </g>
  </svg>
</template>

<script>
import { classVariableName } from "../../utils";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    kind: {
      type: String,
      // validator: (value) => ['filled', 'light-grey', 'dark-grey'].includes(value)
    },
    size: {
      type: String,
      default: "m",
      validator: (value) => ["xs", "s", "m", "l", "xl", "xxl"].includes(value),
    },
    fillPercent: {
      type: Number,
      default: 0,
    },
    pointy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      classVariableName: classVariableName,
    };
  },

  computed: {
    fillColor() {
      switch (this.kind) {
        case "filled":
          return "#FFD947"; // Light/Sparkle/Grey-40
        case "filled-dark":
          return "#F7AB20"; //Light/Secondary/50
        case "light-grey":
          return "#E0E0E0"; // Light/Sparkle/Grey-40
        case "dark-grey":
          return "#B5B5B5"; // Light/Primary/Grey-60
        case "partial":
          return "url(#grad)";
        case "success":
          return "#25AB21"; // Light/Global/Feedback/Success-50
        case "warning":
          return "#F06D0F" // Light/Global/Feedback/Warning-50
        case "error":
          return "#F50031" // Light/Global/Feedback/Error-50
        default:
          return "#E0E0E0"; // Default fill color if kind is not provided or invalid
      }
    },
    sizeClass() {
      return `${this.classVariableName}-star-size-${this.size}`;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/App.scss";
</style>

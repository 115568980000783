import ReviewsRDComponent from "./ReviewsRD.vue";
import AverageRatingRDComponent from "./AverageRatingRD.vue";
import OrdersRatingRDComponent from "./OrdersRatingRD.vue";

window.FPI.extension.register("#rd-widget", {
  mounted(element, props) {
    window.FPI.extension.mountApp({
      element,
      component: ReviewsRDComponent,
      binding: {
        value: props.value
      }
    });
  },
});

window.FPI.extension.register("#rd-avg-widget", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: AverageRatingRDComponent,
    });
  },
});

window.FPI.extension.register("#rd-orders", {
  mounted(element, props) {
    window.FPI.extension.mountApp({
      element,
      component: OrdersRatingRDComponent,
      binding: {
        value: {
          ...props.value, // Spread existing properties of value object
          ingressPoint: "orderDetails" // Add new ingress property
        }
      }
    });
  },
});

window.FPI.extension.register("#rd-thankyou", {
  mounted(element, props) {
    window.FPI.extension.mountApp({
      element,
      component: OrdersRatingRDComponent,
      binding: {
        value: {
          ...props.value, // Spread existing properties of value object
          ingressPoint: "thankYou" // Add new ingress property
        }
      }
    });
  },
});
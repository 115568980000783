<template>
  <div v-if="isMounted" :class="`${classVariableNameRD}-orders-rating-section`">
    <fdk-accounts v-slot="accountsData">
      <div :class="`${classVariableNameRD}-orders-inner-container`">
        <ratingComponent
          :readonly="showAlert"
          @updateRating="
            (event) => {
              rating = event;
              postRating(rating)
            }
          "
          :allowFraction="ratingValidations && parseInt(ratingValidations.step) !== ratingValidations.step"
          :initialRating="rating"
          size="s"
        />
        <div
          :class="`${classVariableNameRD}-review-form-block`"
          @click="openAddReviewPopup(accountsData)"
        >
          <button
            :class="[`${classVariableNameRD}-button-tertiary`, reviewTitle === 'Rate this product' ? `${classVariableNameRD}-review-button-greyed` : `${classVariableNameRD}-cursor-pointer`]"
            :disabled="showAlert===null ? false : showAlert"
          >
            {{ reviewTitle }}
          </button>
        </div>
      </div>
    </fdk-accounts>

    <addReviewRDComponent
      v-if="addReview===true && validationConfig!==null"
      :prdId="productId"
      :varientId="alternateCode1"
      :mstarId="alternateCode2"
      :prdImgURL="productImage"
      :prdName="productName"
      :orderId="orderId"
      :reload="init"
      :onCloseAndRefresh="init"
      :onClose="onClose"
      :showAlert="showAlert===null? false : showAlert"
      :setAlert="(message, type) => toast(message, type)"
      :initialRating="rating"
      :initialReview="userCurrentReview"
      @update:initialReview="updateReview"
      :validationConfig="validationConfig"
      :ingressPoint="ingressPoint"
    />

    <alertComponent
      v-if="showAlert!==null"
      :type="alertType"
      :message="alertMessage"
      :showAlert="showAlert"
    />
  </div>
</template>

<script>
import ratingComponent from "./components/common/StarRating.vue";
import addReviewRDComponent from "./components/AddReviewRD.vue";
import alertComponent from "./components/common/Alert.vue";
import {
  getStorageWithExpiry,
  isEmpty,
  setStorageWithExpiry,
  FEEDBACK_VALIDATION_CONFIG_KEY,
  FEEDBACK_VALIDATION_CONFIG_TTL,
  classVariableNameRD,
  emitEvent,
} from "./utils.js";
import customerServices from "./services/customer-review.js";
import configuration from "./services/configuration.js";

export default {
  name: "OrdersRatingRDComponent",
  components: {
    ratingComponent,
    addReviewRDComponent,
    alertComponent,
  },
  computed: {},
  data() {
    return {
      validationConfig: null,
      ratingValidations: null,
      rating: 0,
      reviewTitle: "Rate this product",
      userCurrentReview: null,
      addReview: false,
      review: null,
      ingressPoint: null,

      showAlert: null,
      alertMessage: null,
      alertType: "primary",

      // product
      product: null,
      productId: null,
      alternateCode1: null,
      alternateCode2: null,
      productName: null,
      productImage: null,
      productURL: null,
      baseURL: null,
      orderId: null,
      isMounted: false,
      classVariableNameRD: classVariableNameRD,
    };
  },
  mounted() {
    console.log("here in orders: ", this.bindingData);
    this.isMounted = true;
    this.baseURL = window.location.origin;
    this.product = this.bindingData.product;
    this.review = this.bindingData.review;
    this.ingressPoint = this.bindingData.ingressPoint;
    this.getProducts(this.product);
    this.getUserReview(this.review);
  },
  destroy() {},
  props: {
    bindingData: Object,
  },
  methods: {
    async isEmpty(val) {
      return isEmpty(val);
    },
    
    async toast(message, type) {
      this.alertMessage = message;
      this.showAlert = true;
      this.alertType = type;
      setTimeout(() => {
        this.showAlert = false;
      }, 3000);
    },

    async postRating(rating) {
      if (!isEmpty(this.userCurrentReview)) {
        let data = {
          productId: this.productId,
          alternateCode1: this.alternateCode1,
          alternateCode2: this.alternateCode2,
          title: this.userCurrentReview.title,
          content: this.userCurrentReview.content,
          productURL: this.productURL,
          rating: rating,
          recommendedToBuy: this.userCurrentReview.recommendedToBuy,
          orderId: this.orderId,
          ingressPoint: this.ingressPoint,
          productName: this.productName
        };
        await customerServices.editReview(this.userCurrentReview.id, data).then((res) => {
          let resp = res.data;
          if (resp.resultInfo.status === "SUCCESS") {
            emitEvent("write_product_review", this.ingressPoint, data);
            //this.toast("Thank you. The review will be updated and published once approved.", "success");
            this.rating = rating;
          } else {
            this.toast(resp.resultInfo.message, "error");
            this.onClose();
          }
        }).catch((error) => {
          this.toast(error.response.data.info.resultInfo.message, "error");
          this.onClose();
        });
      }
      else {
        let data = {
          productId: this.productId,
          alternateCode1: this.alternateCode1,
          alternateCode2: this.alternateCode2,
          title: null,
          content: null,
          productURL: this.productURL,
          images: undefined,
          rating: rating,
          recommendedToBuy: null,
          orderId: this.orderId,
          ingressPoint: this.ingressPoint,
          productName: this.productName
        };
        await customerServices.postReview(data).then((res) => {
          let resp = res.data;
          if (resp.resultInfo.status === "SUCCESS") {
            emitEvent("write_product_review", this.ingressPoint, data);
            // this.toast("Thank you for submitting the rating. The rating will be published once approved.", "success");
            let newReview = {
              "title": null,
              "content": null,
              "recommendedToBuy": null,
              "rating": rating
            }
            newReview.id = resp.data.id;
            this.userCurrentReview = newReview;
            this.rating = newReview.rating;
            this.reviewTitle = "Write a Review";
          } else {
            this.toast(resp.resultInfo.message, "error");
            this.onClose();
          }
        }).catch((error) => {
          this.toast(error.response.data.info.resultInfo.message, "error");
          this.onClose();
        });
      }
    },

    async openAddReviewPopup(accountsData) {
      if (this.reviewTitle === 'Rate this product') {
        return;
      }
      if (!accountsData.is_logged_in) {
        accountsData.openLogin();
      } else {
        await this.getReviewValidationConfig();
        this.addReview = true;
      }
    },

    updateReview(newReview) {
      this.userCurrentReview = newReview;
      this.rating = newReview.rating;
      this.reviewTitle = "Edit Review";
    },

    async getUserReview(review) {
      if(isEmpty(review) || Object.keys(this.review).length===0) {
        this.rating = 0;
      }
      else {
        this.userCurrentReview = review;
        this.reviewTitle = "Edit Review";
        this.rating = this.userCurrentReview.rating;
      }
    },

    async checkIfReviewInPendingState(reviewMap) {
      return !isEmpty(reviewMap["PENDING"]);
    },

    async getReviewValidationConfig() {
      var config = getStorageWithExpiry(FEEDBACK_VALIDATION_CONFIG_KEY);
      if (!isEmpty(config)) {
        this.validationConfig = config;
        this.ratingValidations = config.rating;
        return;
      }
      return await configuration
        .getValidationConfig()
        .then((data) => {
          let res = data.data;
          if (res.resultInfo.status === "SUCCESS") {
            config = res.data.data;
            this.validationConfig = config;
            this.ratingValidations = config.rating;
            setStorageWithExpiry(
              FEEDBACK_VALIDATION_CONFIG_KEY,
              config,
              FEEDBACK_VALIDATION_CONFIG_TTL
            );
          } else {
            this.toast(res.resultInfo.message, "error");
          }
        })
        .catch((error) => console.error(error));
    },

    async getProducts(product) {
      this.productId = product.item_code;
      this.alternateCode1 = product.item_code;
      this.alternateCode2 = product.uid;
      this.productName = product.name;
      this.productImage = product.item_image_url;
      this.orderId = product.order_id;
      this.productURL =
        this.baseURL + "/" + product.type + "/" + product.slug;
    },

    init() {
      this.addReview = false;
    },

    onClose() {
      this.addReview = false;
      if(isEmpty(this.userCurrentReview)) {
        this.reviewTitle = 'Rate this product';
        this.rating = 0;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/AppRD.scss";
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('fdk-accounts',{scopedSlots:_vm._u([{key:"default",fn:function(accountsData){return [(_vm.validationConfig)?_c('reviewRDModalComponent',{attrs:{"prdImgURL":_vm.prdImgURL,"prdName":_vm.prdName,"onClose":_vm.onClose,"onSubmit":(e) => {
        if (!accountsData.is_logged_in) {
          accountsData.openLogin();
        } else {
          _vm.handleSubmitFile(e);
        }
      },"showAlert":_vm.showAlert,"initialRating":_vm.initialRating,"validationConfig":_vm.validationConfig,"initialReview":_vm.initialReview},model:{value:(_vm.review),callback:function ($$v) {_vm.review=$$v},expression:"review"}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./CertifiedBuyer.vue?vue&type=template&id=599746f5&scoped=true"
import script from "./CertifiedBuyer.vue?vue&type=script&lang=js"
export * from "./CertifiedBuyer.vue?vue&type=script&lang=js"
import style0 from "./CertifiedBuyer.vue?vue&type=style&index=0&id=599746f5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599746f5",
  null
  
)

export default component.exports
<template>
  <div>
    <ModalRD
      importStyle="AppRD"
      ctaTitle="Submit"
      :ctaAction="handleSubmit"
      :ctaActionDisabled="
        ratingError || imageError || titleError || contentError || showAlert || recommendError
      "
      :closeModal="onClose"
    >
      <template #modalTitle>
        <div :class="`${classVariableNameRD}-jds-desk-body-m-bold ${classVariableNameRD}-jds-color-grey-100`">
          Write a Review
        </div>
      </template>

      <template #default>
        <div
          :class="`${classVariableNameRD}-margin-bottom-16
                ${classVariableNameRD}-d-flex 
                ${classVariableNameRD}-flex-row`"
        >
          <div :style="{ width: '25%' }">
            <img
              :src="prdImgURL"
              :style="{
                width: '100%',
                margin: '0px 24px 0 0px',
                border: '0.803571px solid #E0E0E0',
                borderRadius: '16px',
              }"
              alt="productImage..."
            />
          </div>
          <div
            :style="{
              display: 'flex',
              marginLeft: '12px',
              flexDirection: 'column',
              alignItems: 'center',
            }"
          >
            <div :class="`${classVariableNameRD}}-input-helper ${classVariableNameRD}-input-text`">
              {{ prdName }}
            </div>
          </div>
        </div>

        <div :class="`${classVariableNameRD}-modal-body-section`">
          <div
            v-if="ratingValidations && !ratingValidations.hidden"
            :class="`${classVariableNameRD}-modal-sub-section`"
          >
            <div :class="`${classVariableNameRD}-jds-desk-body-xs-bold ${classVariableNameRD}-jds-color-grey-100`">
              Select rating*
            </div>
            <ratingComponent
              :class="`${classVariableNameRD}-justify-content-space-between`"
              :readonly="showAlert"
              @updateRating="
                (event) => {
                  rating = event;
                  ratingError = null;
                }
              "
              :allowFraction="parseInt(ratingValidations.step) !== ratingValidations.step"
              :initialRating="rating"
              size="xl"
            />

            <div
              v-if="ratingError"
              :class="`${classVariableNameRD}-modal-input-error-text`"
            >
              {{ ratingError }}
            </div>
          </div>

          <!-- v-if="recommendValidations && !recommendValidations.hidden" -->
          <div :class="`${classVariableNameRD}-modal-reaction-sub-section`">
            <div :class="`${classVariableNameRD}-modal-reaction-sub-section-line1`">
              <div :class="`${classVariableNameRD}-modal-section-header`">
                Recommended to buy*
              </div>
              <div :class="`${classVariableNameRD}-modal-reactions`">
                <div
                  :class="`${classVariableNameRD}-modal-reaction ${readOnly ? '' : `${classVariableNameRD}-cursor-pointer`}`"
                  :style="{background: (recommendedToBuy==='upvote' || recommendationHover==='upvote') ? '#E7EBF8' : '#F5F5F5'}"
                  @mouseenter="()=> {if(!readOnly) recommendationHover='upvote'}"
                  @mouseleave="()=>{recommendationHover=''}"
                  @click="()=>{recommendedToBuy='upvote'; recommendError = null;}"
                  :readOnly="showAlert"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      :fill="(recommendedToBuy==='upvote'||recommendationHover==='upvote') ? '#3535f3' : '#000000A6'"
                      d="M3 11.004V18.004C3 18.5344 3.21071 19.0431 3.58579 19.4182C3.96086 19.7933 4.46957 20.004 5 20.004H7V9.00398H5C4.46957 9.00398 3.96086 9.21469 3.58579 9.58977C3.21071 9.96484 3 10.4735 3 11.004ZM20.24 10.004C19.9582 9.68888 19.6129 9.4369 19.2269 9.26456C18.8409 9.09223 18.4228 9.00343 18 9.00398H14V5.08398C14.0162 4.69568 13.9188 4.31109 13.7199 3.97721C13.521 3.64334 13.2292 3.37464 12.88 3.20398C12.6123 3.07285 12.3188 3.00319 12.0207 3.00011C11.7227 2.99702 11.4278 3.06059 11.1574 3.18616C10.8871 3.31173 10.6483 3.49612 10.4584 3.72586C10.2686 3.9556 10.1324 4.22487 10.06 4.51398L9.06 8.51398C9.02099 8.67442 9.00085 8.83887 9 9.00398V20.004H17.44C18.1816 20.0085 18.8987 19.7381 19.4527 19.245C20.0067 18.752 20.3584 18.0711 20.44 17.334L20.99 12.334C21.0358 11.9147 20.9927 11.4905 20.8634 11.089C20.7342 10.6875 20.5218 10.3178 20.24 10.004Z"
                    />
                  </svg>
                </div>
                <div
                  :class="`${classVariableNameRD}-modal-reaction ${readOnly ? '' : `${classVariableNameRD}-cursor-pointer`}`"
                  :style="{background: (recommendedToBuy==='downvote'||recommendationHover==='downvote') ? '#E7EBF8' : '#F5F5F5'}"
                  @mouseenter="()=> {if(!readOnly) recommendationHover='downvote'}"
                  @mouseleave="()=>{recommendationHover=''}"
                  @click="()=>{recommendedToBuy='downvote'; recommendError = null;}"
                  :readOnly="showAlert"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      :fill="(recommendedToBuy==='downvote'||recommendationHover==='downvote') ? '#3535f3' : '#000000A6'"
                      d="M3.56821 6.67781L3.01821 11.6778C2.97182 12.0969 3.01431 12.5211 3.14289 12.9227C3.27147 13.3242 3.48327 13.6942 3.76445 14.0084C4.04564 14.3226 4.3899 14.574 4.77479 14.7462C5.15968 14.9184 5.57655 15.0076 5.99821 15.0078H9.99821V18.9278C9.98205 19.3161 10.0794 19.7007 10.2783 20.0346C10.4772 20.3685 10.769 20.6371 11.1182 20.8078C11.3865 20.9389 11.6808 21.0082 11.9794 21.0106C12.278 21.0131 12.5734 20.9487 12.8438 20.8221C13.1143 20.6955 13.353 20.5099 13.5423 20.2791C13.7317 20.0482 13.867 19.7778 13.9382 19.4878L14.9382 15.4878C14.9776 15.3308 14.9977 15.1697 14.9982 15.0078V4.00781H6.54821C5.81 4.00825 5.09788 4.28086 4.54808 4.77346C3.99828 5.26607 3.64941 5.94409 3.56821 6.67781ZM18.9982 4.00781H16.9982V15.0078H18.9982C19.5286 15.0078 20.0373 14.7971 20.4124 14.422C20.7875 14.047 20.9982 13.5382 20.9982 13.0078V6.00781C20.9982 5.47738 20.7875 4.96867 20.4124 4.5936C20.0373 4.21853 19.5286 4.00781 18.9982 4.00781Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              v-if="recommendError"
              :class="`${classVariableNameRD}-modal-input-error-text`"
            >
              {{ recommendError }}
            </div>
          </div>

          <div
            v-if="titleValidations && !titleValidations.hidden"
            :class="`${classVariableNameRD}-modal-sub-section`"
          >
            <Input
              :value="title"
              @updateInput="
                (event) => {
                  title = event;
                  titleError = null;
                }
              "
              @hasError="
                (event) => {
                  titleError = event;
                }
              "
              :maxCharCount="titleValidations.maxCharLimit"
              :minCharCount="titleValidations.minCharLimit"
              placeholder="Add review title*"
              :readOnly="showAlert"
            />
            <div
              v-if="titleError"
              :class="`${classVariableNameRD}-modal-input-error-text`"
            >
              {{ titleError }}
            </div>
          </div>

          <div
            v-if="contentValidations && !contentValidations.hidden"
            :class="`${classVariableNameRD}-modal-sub-section`"
          >
            <div :class="`${classVariableNameRD}-modal-section-header`">
              Please provide detailed feedback about the product.*
            </div>
            <Textarea
              :value="content"
              @updateInput="
                (event) => {
                  content = event;
                  contentError = null;
                }
              "
              @hasError="
                (event) => {
                  contentError = event;
                }
              "
              :maxCharCount="contentValidations.maxCharLimit"
              :minCharCount="contentValidations.minCharLimit"
              placeholder="Description*"
              :readOnly="showAlert"
            />
            <div
              v-if="contentError"
              :class="`${classVariableNameRD}-modal-input-error-text`"
            >
              {{ contentError }}
            </div>
          </div>

          <div
            v-if="imageValidations && !imageValidations.hidden"
            :class="`${classVariableNameRD}-modal-sub-section`"
          >
            <div :class="`${classVariableNameRD}-modal-section-header`">
              Add Images
            </div>
            <div
              v-if="images !== undefined && images.length > 0"
              :class="`${classVariableNameRD}-d-flex ${classVariableNameRD}-flex-wrap`"
            >
              <div
                v-for="(img, index) in images"
                :key="index"
                :class="`${classVariableNameRD}-img-container`"
              >
                <div
                  :class="`${classVariableNameRD}-closable`"
                  @click="() => removeImage(index)"
                >
                  x
                </div>
                <img
                  :key="index"
                  :src="getImage(img)"
                  alt="image preview"
                  :class="`${classVariableNameRD}-imgContainer ${classVariableNameRD}-d-flex`"
                />
              </div>
            </div>
            <div :style="{display:'flex', width:'100%'}">
              <div
                :class="`${classVariableNameRD}-review-button
                          ${classVariableNameRD}-w-100 
                          ${classVariableNameRD}-ml-0`"
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.21019 8.70994L11.5002 5.40994V16.9999C11.5002 17.2652 11.6055 17.5195 11.7931 17.707C11.9806 17.8946 12.235 17.9999 12.5002 17.9999C12.7654 17.9999 13.0198 17.8946 13.2073 17.707C13.3948 17.5195 13.5002 17.2652 13.5002 16.9999V5.40994L16.7902 8.70994C16.8831 8.80367 16.9937 8.87806 17.1156 8.92883C17.2375 8.9796 17.3682 9.00574 17.5002 9.00574C17.6322 9.00574 17.7629 8.9796 17.8848 8.92883C18.0066 8.87806 18.1172 8.80367 18.2102 8.70994C18.3039 8.61698 18.3783 8.50637 18.4291 8.38452C18.4798 8.26266 18.506 8.13195 18.506 7.99994C18.506 7.86793 18.4798 7.73722 18.4291 7.61536C18.3783 7.4935 18.3039 7.3829 18.2102 7.28994L13.2102 2.28994C13.1172 2.19621 13.0066 2.12182 12.8848 2.07105C12.7629 2.02028 12.6322 1.99414 12.5002 1.99414C12.3682 1.99414 12.2375 2.02028 12.1156 2.07105C11.9937 2.12182 11.8831 2.19621 11.7902 2.28994L6.79019 7.28994C6.60188 7.47824 6.49609 7.73364 6.49609 7.99994C6.49609 8.26624 6.60188 8.52164 6.79019 8.70994C6.97849 8.89824 7.23388 9.00403 7.50019 9.00403C7.76649 9.00403 8.02188 8.89824 8.21019 8.70994ZM17.5002 19.9999H7.50019C7.23497 19.9999 6.98062 20.1053 6.79308 20.2928C6.60554 20.4804 6.50019 20.7347 6.50019 20.9999C6.50019 21.2652 6.60554 21.5195 6.79308 21.707C6.98062 21.8946 7.23497 21.9999 7.50019 21.9999H17.5002C17.7654 21.9999 18.0198 21.8946 18.2073 21.707C18.3948 21.5195 18.5002 21.2652 18.5002 20.9999C18.5002 20.7347 18.3948 20.4804 18.2073 20.2928C18.0198 20.1053 17.7654 19.9999 17.5002 19.9999Z"
                    fill="#0C5273"
                  />
                </svg>
                Upload images
              </div>

              <input
                id="imgInput"
                type="file"
                multiple
                title="Upload Images..."
                :style="{
                  width: '385px',
                  position: 'absolute',
                  opacity: '0',
                  height: '48px',
                  color: 'transparent',
                }"
                :disabled="showAlert"
                @input="handleImagePreview"
                accept="image/*"
              />
            </div>
            <div :class="`${classVariableNameRD}}-modal-section-helper`">
              Supports png, jpeg, jpg, svg with maximum file size of
              {{ imageValidations.sizeLimitInMegaBytes }}MB each.
            </div>

            <div
              v-if="imageError"
              :class="`${classVariableNameRD}-modal-input-error-text`"
            >
              {{ imageError }}
            </div>
          </div>

          <div
            :class="`${classVariableNameRD}-modal-sub-section`"
          >
            <div :class="`${classVariableNameRD}-modal-tc-section`">
              <input
                type="checkbox"
                v-model="tcIsChecked"
                @change="() => {if(tcIsChecked) tcError=null}"
              >
              <div :class="`${classVariableNameRD}-jds-desk-body-xxs ${classVariableNameRD}-jds-color-grey-80`">
                I have read and accepted the review <a href="/c/terms-of-use" target="_blank" :class="`${classVariableNameRD}-jds-desk-body-xxs-link`">Policy & terms</a>
              </div>
            </div>
            <div
              v-if="tcError"
              :class="`${classVariableNameRD}-modal-input-error-text`"
            >
              {{ tcError }}
            </div>
          </div>
        </div>
      </template>
    </ModalRD>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { isEmpty, classVariableNameRD } from "../utils";
import ModalRD from "./common/ModalRD.vue";
import ratingComponent from "./common/StarRating.vue";
import Input from "./common/Input.vue";
import Textarea from "./common/Textarea.vue";

export default defineComponent({
  name: "reviewRDModalComponent",
  components: {
    ModalRD,
    Input,
    Textarea,
    ratingComponent,
  },
  props: {
    prdImgURL: {
      type: String,
      default: "",
    },
    prdName: {
      type: String,
      default: "",
    },
    showAlert: {
      type: Boolean,
      default: false,
    },
    initialRating: {
      type: Number,
      default: 0.0,
    },
    initialReview: {
      type: Object,
      default: null
    },
    validationConfig: {
      type: Object,
      default: {},
    },
    onClose: Function,
    onSubmit: Function,
  },
  mounted() {},
  setup() {},
  data() {
    return {
      titleValidations: this.validationConfig && this.validationConfig.title,
      recommendValidations: this.validationConfig && this.validationConfig.upvote,
      contentValidations: this.validationConfig && this.validationConfig.content,
      ratingValidations: this.validationConfig && this.validationConfig.rating,
      imageValidations: this.validationConfig && this.validationConfig.image,
      title: this.initialReview && this.initialReview.title,
      recommendedToBuy: this.initialReview && (this.initialReview.recommendedToBuy === true ? 'upvote' : this.initialReview.recommendedToBuy == false ? 'downvote' : ''),
      recommendationHover: '',
      content: this.initialReview && this.initialReview.content,
      images: this.initialReview && this.initialReview.images || undefined,
      rating: this.initialRating,
      tcIsChecked: true,
      ratingError: null,
      titleError: null,
      recommendError: null,
      contentError: null,
      imageError: null,
      tcError: null,
      classVariableNameRD: classVariableNameRD,
    };
  },
  methods: {
    isEmpty(val) {
      return isEmpty(val);
    },
    handleSubmit() {
      if (!this.validateFieldsSuccess()) return;
      this.onSubmit({
        title: isEmpty(this.title) ? null : this.title.trim(),
        content: isEmpty(this.content) ? null : this.content.trim(),
        recommendedToBuy: isEmpty(this.recommendedToBuy) ? null : this.recommendedToBuy === 'downvote' ? false : this.recommendedToBuy === 'upvote' ? true : '',
        rating: this.rating,
        images: this.images,
      });
    },

    validateFieldsSuccess() {
      let ratingCheckFail =
        this.ratingValidations &&
        !this.ratingValidations.hidden &&
        this.ratingValidations.required &&
        this.rating === 0;
      let titleCheckFail =
        this.titleValidations &&
        !this.titleValidations.hidden &&
        // this.titleValidations.required &&
        isEmpty(this.title);
      let recommendCheckFail = 
        this.recommendValidations &&
        !this.recommendValidations.hidden &&
        // this.recommendValidations.required &&
        isEmpty(this.recommendedToBuy);
      let contentCheckFail =
        this.contentValidations &&
        !this.contentValidations.hidden &&
        // this.contentValidations.required &&
        isEmpty(this.content);
      let imageCheckFail =
        this.imageValidations &&
        !this.imageValidations.hidden &&
        this.imageValidations.required &&
        isEmpty(this.images);
      if (
        ratingCheckFail ||
        titleCheckFail ||
        recommendCheckFail ||
        contentCheckFail ||
        imageCheckFail ||
        !this.tcIsChecked
      ) {
        if (ratingCheckFail) this.ratingError = "Rating is required!";
        if (titleCheckFail) this.titleError = "Please provide a title";
        if (recommendCheckFail) this.recommendError = "Please provide your recommendation";
        if (contentCheckFail) this.contentError = "Please provide the description";
        if (imageCheckFail) this.imageError = "Please provide at least 1 image to support";
        if (!this.tcIsChecked) this.tcError = "Please agree to the policy and terms";
        return false;
      }
      return true;
    },
    removeImage(imgIndex) {
      this.imageError = null;
      let imgs = [...this.images];
      imgs.splice(imgIndex, 1);
      this.images = imgs;
    },
    getImage(img) {
      let image_as_base64 = URL.createObjectURL(img);
      return image_as_base64;
    },
    handleImagePreview(e) {
      this.imageError = null;
      let image_as_files = e.target.files;
      let imgs = [];
      if (!isEmpty(this.images)) {
        imgs = [...this.images];
      }

      for (var i = 0; i < image_as_files.length; i++) {
        let file = image_as_files[i];
        let fileSizeKiloBytes = file.size / 1024;
        if (
          fileSizeKiloBytes >
          this.imageValidations.sizeLimitInMegaBytes * 1024
        ) {
          this.imageError = `Maximum File Size exceeds ${this.imageValidations.sizeLimitInMegaBytes}MB!`;
          document.getElementById("imgInput").value = "";
          return;
        }

        if (!imgs.some((image) => image.name === file.name)) imgs.push(file);
      }

      if (imgs.length > this.imageValidations.countLimit) {
        this.imageError = `Maximum ${this.imageValidations.countLimit} images are allowed on a review!`;
        document.getElementById("imgInput").value = "";
        return;
      }

      document.getElementById("imgInput").value = "";
      this.images = imgs;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "../assets/AppRD.scss";
</style>

<template>
  <div>
    <textarea
      :class="`${classVariableName}-input`"
      :style="{ lineHeight: '24px', height: 'auto' }"
      v-model="inputValue"
      @input="onInputChange"
      @blur="onInputBlur"
      :maxlength="maxCharCount"
      :placeholder="placeholder"
      :rows="rows"
      :disabled="readOnly"
    />
    <div
      :class="`${classVariableName}-input-helper ${classVariableName}-input-text`"
      v-if="maxCharCount"
    >
      {{ charCount }}/{{ maxCharCount }} characters
    </div>
  </div>
</template>

<script>
import { classVariableName } from "../../utils";

export default {
  props: {
    value: String, // The initial value of the input
    maxCharCount: Number,
    minCharCount: {
      type: Number,
      default: 5,
    },
    placeholder: String,
    isRequired: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      classVariableName: classVariableName,
      inputValue: this.value || "", // Initialize input value
      charCount: this.value ? this.value.length : 0, // Initialize character count
    };
  },
  methods: {
    onInputChange() {
      if (this.readOnly) return;
      this.charCount = this.inputValue.trim().length;
      if (this.charCount > this.maxCharCount) {
        this.inputValue = this.inputValue.slice(0, this.maxCharCount);
        this.charCount = this.maxCharCount;
      }
      this.$emit("updateInput", this.inputValue);
    },
    onInputBlur() {
      if (this.readOnly) return;
      if (this.isRequired) {
        if (this.minCharCount && this.charCount < this.minCharCount) {
          this.$emit(
            "hasError",
            `Minimum ${this.minCharCount} characters required.`
          );
        }
      } else {
        if (
          this.minCharCount &&
          this.charCount > 0 &&
          this.charCount < this.minCharCount
        ) {
          this.$emit(
            "hasError",
            `Minimum ${this.minCharCount} characters required.`
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/App.scss";
</style>

<template>
  <fdk-accounts v-slot="accountsData">
    <reviewRDModalComponent
      v-if="validationConfig"
      v-model="review"
      :prdImgURL="prdImgURL"
      :prdName="prdName"
      :onClose="onClose"
      :onSubmit="
        (e) => {
          if (!accountsData.is_logged_in) {
            accountsData.openLogin();
          } else {
            handleSubmitFile(e);
          }
        }
      "
      :showAlert="showAlert"
      :initialRating="initialRating"
      :validationConfig="validationConfig"
      :initialReview="initialReview"
    />
  </fdk-accounts>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import reviewRDModalComponent from "./AddReviewModalRD.vue";
import customerServices from "../services/customer-review.js";
import { isEmpty, emitEvent } from "../utils";

export default defineComponent({
  name: "addReviewRDComponent",
  components: {
    reviewRDModalComponent,
  },
  props: {
    prdId: String,
    varientId: String,
    mstarId: String,
    prdlink: String,
    prdImgURL: String,
    prdName: String,
    categoryL1Id: String,
    categoryL2Id: String,
    categoryL3Id: String,
    categoryL4Id: String,
    categoryL1Name: String,
    categoryL2Name: String,
    categoryL3Name: String,
    categoryL4Name: String,
    orderId: String,
    showAlert: Boolean,
    initialRating: String,
    initialReview: Object,
    validationConfig: Object,
    onClose: Function,
    onCloseAndRefresh: Function,
    setAlert: Function,
    ingressPoint: String,
    location: Object,
  },
  mounted() {},
  setup() {},
  data() { return {
    review: this.initialReview,
  } },
  methods: {
    async isEmpty(val) {
      return isEmpty(val);
    },

    async updateInitialReview(review, id) {
      this.review = review;
      this.review.id = id;
      this.$emit('update:initialReview', this.review);
    },

    async transformImageUrls(images) {
      let imgs = [];
      images.map((img) => imgs.push(img.fileUrl));
      return imgs;
    },

    async handleSubmitFile(review) {
      let images = review.images;
      if (images !== undefined && images.length > 0) {
        let formData = new FormData();
        for (let i = 0; i < images.length; i++) {
          formData.append("files", images[i]);
        }

        await customerServices.addAttachments(formData).then(async (data) => {
          let resp = data.data;
          if (resp.resultInfo.status === "SUCCESS") {
            let imgs = await this.transformImageUrls(resp.data);
            await this.postReview(review, imgs);
          } else {
            this.setAlert(resp.resultInfo.message, "error");
          }
        });
      } else {
        await this.postReview(review);
      }
    },

    async postReview(review, images = []) {
      let imgPayload = [];
      images.map((img) => imgPayload.push({ url: img }));
      let data = {
        productId: this.prdId,
        alternateCode1: this.varientId,
        alternateCode2: this.mstarId,
        title: !isEmpty(review.title) ? review.title : null,
        content: !isEmpty(review.content) ? review.content : null,
        productURL: this.prdlink,
        categoryL1: !isEmpty(this.categoryL1Id)
          ? { id: this.categoryL1Id, name: this.categoryL1Name }
          : undefined,
        categoryL2: !isEmpty(this.categoryL2Id)
          ? { id: this.categoryL2Id, name: this.categoryL2Name }
          : undefined,
        categoryL3: !isEmpty(this.categoryL3Id)
          ? { id: this.categoryL3Id, name: this.categoryL3Name }
          : undefined,
        categoryL4: !isEmpty(this.categoryL4Id)
          ? { id: this.categoryL4Id, name: this.categoryL4Name }
          : undefined,
        images:
          imgPayload === undefined || imgPayload.length === 0
            ? undefined
            : imgPayload,
        rating: review.rating,
        recommendedToBuy: review.recommendedToBuy,
        orderId: this.orderId,
        ingressPoint: this.ingressPoint,
        productName: this.prdName,
        location: window.FPI.state.global.location._data || this.location || { pincode: "400706", city: "Navi Mumbai", state: "Maharashtra" },
      };
      if (!isEmpty(this.initialReview) && this.initialReview.id) {
        await customerServices.editReview(this.initialReview.id, data).then((res) => {
          let resp = res.data;
          if (resp.resultInfo.status === "SUCCESS") {
            emitEvent("write_product_review", this.ingressPoint, data);
            console.log("after emit");
            this.setAlert("Thank you. The review will be updated and published once approved.", "success");
            this.updateInitialReview(review, this.initialReview.id);
            this.onCloseAndRefresh();
          } else {
            this.setAlert(resp.resultInfo.message, "error");
            this.onClose();
          }
        }).catch((error) => {
          this.setAlert(error.response.data.info.resultInfo.message, "error");
          this.onClose();
        });
      }
      else {
        await customerServices.postReview(data).then((res) => {
          let resp = res.data;
          if (resp.resultInfo.status === "SUCCESS") {
            emitEvent("write_product_review", this.ingressPoint, data);
            this.setAlert("Thank you for submitting the review. The review will be published once approved.", "success");
            this.updateInitialReview(review, resp.data.id);
            this.onCloseAndRefresh();
          } else {
            this.setAlert(resp.resultInfo.message, "error");
            this.onClose();
          }
        }).catch((error) => {
          this.setAlert(error.response.data.info.resultInfo.message, "error");
          this.onClose();
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/App.scss";
</style>

<template>
  <div class="accordion-item" :class="{ 'active': isOpen }">
    <div class="accordion-header" @click="toggleAccordion">
      <slot name="header"></slot>
      <div class="arrow" :class="isOpen ? 'rotate' : 'rotate-reverse'">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
          <path d="M7.00025 9.00057C6.82477 9.00159 6.65082 8.96795 6.48837 8.9016C6.32592 8.83525 6.17817 8.73748 6.05358 8.6139L0.720247 3.28057C0.469176 3.0295 0.328125 2.68897 0.328125 2.33391C0.328125 1.97884 0.469176 1.63831 0.720247 1.38724C0.971319 1.13617 1.31185 0.995117 1.66691 0.995117C2.02198 0.995117 2.36251 1.13617 2.61358 1.38724L7.00025 5.78724L11.3869 1.38724C11.638 1.13617 11.9785 0.995117 12.3336 0.995117C12.6886 0.995117 13.0292 1.13617 13.2802 1.38724C13.5313 1.63831 13.6724 1.97884 13.6724 2.33391C13.6724 2.68897 13.5313 3.0295 13.2802 3.28057L7.94691 8.6139C7.82233 8.73748 7.67457 8.83525 7.51212 8.9016C7.34967 8.96795 7.17572 9.00159 7.00025 9.00057Z" fill="#000093"/>
        </svg>
      </div>
    </div>
    <div v-show="isOpen" class="accordion-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.accordion-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.arrow {
  transition: transform .5s ease;
  height: 16px;
}

.rotate {
  transform: rotate(180deg);
}

.rotate-reverse {
  transform: rotate(0deg);
}

.accordion-content {
  padding: 16px 0px;
  display: none;
}

.accordion-item.active .accordion-content {
  display: block;
}
</style>

<template>
  <div :class="`${classVariableName}-modal`">
    <div :class="`${classVariableName}-modal-content`">
      <div :class="`${classVariableName}-modal-content-header`">
        <slot name="modalTitle"></slot>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
          @click="closeModal"
          :style="{cursor:'pointer'}"
        >
          <path
            d="M8.94028 8.00794L13.1403 3.8146C13.2658 3.68907 13.3363 3.5188 13.3363 3.34127C13.3363 3.16374 13.2658 2.99347 13.1403 2.86794C13.0147 2.7424 12.8445 2.67187 12.667 2.67188C12.4894 2.67188 12.3192 2.7424 12.1936 2.86794L8.00028 7.06794L3.80695 2.86794C3.68142 2.7424 3.51115 2.67188 3.33362 2.67188C3.15608 2.67188 2.98582 2.7424 2.86028 2.86794C2.73475 2.99347 2.66422 3.16374 2.66422 3.34127C2.66422 3.5188 2.73475 3.68907 2.86028 3.8146L7.06028 8.00794L2.86028 12.2013C2.7978 12.2632 2.7482 12.337 2.71436 12.4182C2.68051 12.4995 2.66309 12.5866 2.66309 12.6746C2.66309 12.7626 2.68051 12.8497 2.71436 12.931C2.7482 13.0122 2.7978 13.086 2.86028 13.1479C2.92226 13.2104 2.99599 13.26 3.07723 13.2939C3.15847 13.3277 3.24561 13.3451 3.33362 13.3451C3.42163 13.3451 3.50876 13.3277 3.59 13.2939C3.67124 13.26 3.74498 13.2104 3.80695 13.1479L8.00028 8.94794L12.1936 13.1479C12.2556 13.2104 12.3293 13.26 12.4106 13.2939C12.4918 13.3277 12.5789 13.3451 12.667 13.3451C12.755 13.3451 12.8421 13.3277 12.9233 13.2939C13.0046 13.26 13.0783 13.2104 13.1403 13.1479C13.2028 13.086 13.2524 13.0122 13.2862 12.931C13.3201 12.8497 13.3375 12.7626 13.3375 12.6746C13.3375 12.5866 13.3201 12.4995 13.2862 12.4182C13.2524 12.337 13.2028 12.2632 13.1403 12.2013L8.94028 8.00794Z"
            fill="#000093"
          />
        </svg>
      </div>
      <div :class="`${classVariableName}-modal-body`">
        <slot name="default"></slot>
      </div>

      <div
        v-if="!isEmpty(ctaTitle)"
        :class="`${classVariableName}-modal-footer`"
      >
        <div
          :class="`${classVariableName}-modal-footer-button-div`"
          v-if="!isEmpty(ctaTitle)"
        >
          <button
            :class="`${classVariableName}-modal-footer-button`"
            :disabled="ctaActionDisabled"
            @click="ctaAction"
          >
            {{ ctaTitle }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { classVariableNameRD, isEmpty } from "../../utils";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  mounted() {},
  data() {
    return {
      classVariableName: classVariableNameRD,
    };
  },
  props: {
    modalTitle: String,
    ctaTitle: String,
    ctaAction: Function,
    ctaActionDisabled: Boolean,
    closeModal: Function,
  },
  methods: {
    isEmpty(val) {
      return isEmpty(val);
    },
    closeOnClickOutside(event) {
      if (event.target === this.$el) {
        this.closeModal();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/AppRD.scss";
</style>

<template>
  <div v-if="isMounted && ratingsCount!==0" :class="`${classVariableNameRD}-average-rating-section`" @click="scrollToReviewSection">
    <Star :size="xs" kind="filled-dark" :pointy="false" />
    <span :class="`${classVariableNameRD}-average-rating-total-count`">
      {{ avgRating }}
    </span>
    <span :class="`${classVariableNameRD}-jds-desk-body-s ${classVariableNameRD}-jds-color-grey-80`">
      ({{ratingsCount}} Ratings<template v-if="reviewsCount!==0"> & {{reviewsCount}} Reviews</template>)
    </span>
  </div>
</template>

<script>
import Star from "./components/common/Star.vue";
import { isEmpty, classVariableNameRD } from "./utils.js";
import customerServices from "./services/customer-review.js";

export default {
  name: "AverageRatingRDComponent",
  components: {
    Star
  },
  computed: {},
  data() {
    return {
      avgRating: 0,
      ratingsCount: 0,
      reviewsCount: 0,
      showAlert: null,
      alertMessage: null,
      alertType: "primary",

      productId: null,

      baseURL: null,
      isMounted: false,
      classVariableNameRD: classVariableNameRD,
    };
  },
  mounted() {
    this.isMounted = true;
    this.baseURL = window.location.origin;
    window.FPI.state.product.subscribe(this.getProducts.bind(this));
  },
  destroy() {
    window.FPI.state.product.unsubscribe(this.getProducts.bind(this));
  },
  methods: {
    scrollToReviewSection() {
      const reviewSection = document.getElementById("rd-widget");
      // const reviewSection = document.getElementsByClassName(`${classVariableNameRD}-custom-container`)[0];
      reviewSection.scrollIntoView({ behavior: 'smooth' });
  
      // Adjust scroll position to be 20% from the top of the visible display
      const windowHeight = window.innerHeight;
      const scrollToPosition = reviewSection.getBoundingClientRect().top + window.scrollY - (windowHeight * 0.2);
      window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
    },

    async toast(message, type) {
      this.alertMessage = message;
      this.showAlert = true;
      this.alertType = type;
      setTimeout(() => {
        this.showAlert = false;
      }, 3000);
    },

    async getProductSummary() {
      return await customerServices
        .getProductStats(this.productId)
        .then((data) => {
          let res = data.data;
          if (res.resultInfo.status !== "SUCCESS") {
            this.toast(res.resultInfo.message, "danger");
          } else if (
            res.resultInfo.status === "SUCCESS" &&
            !isEmpty(res.data)
          ) {
            this.avgRating = res.data.averageRating;
            this.ratingsCount = res.data.ratingsCount;
            this.reviewsCount = res.data.reviewsCount;
          }
        })
        .catch((error) => console.error(error));
    },

    async getProducts(product) {
      this.productId = product.product.item_code;
      this.getProductSummary();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/AppRD.scss";
</style>

<template>
  <div :class="`${classVariableName}-certified-buyer-container`">
      <svg :class="sizeClass" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          :fill="fillColor"
          d="M8.00017 1.33594C6.68162 1.33594 5.39269 1.72693 4.29636 2.45947C3.20004 3.19202 2.34555 4.23321 1.84097 5.45138C1.33638 6.66956 1.20436 8.01 1.4616 9.30321C1.71883 10.5964 2.35377 11.7843 3.28612 12.7167C4.21847 13.649 5.40636 14.2839 6.69956 14.5412C7.99277 14.7984 9.33322 14.6664 10.5514 14.1618C11.7696 13.6572 12.8108 12.8027 13.5433 11.7064C14.2758 10.6101 14.6668 9.32115 14.6668 8.0026C14.6668 7.12713 14.4944 6.26022 14.1594 5.45138C13.8243 4.64254 13.3333 3.90762 12.7142 3.28856C12.0952 2.6695 11.3602 2.17844 10.5514 1.84341C9.74255 1.50838 8.87565 1.33594 8.00017 1.33594ZM11.4735 6.47594L7.4735 10.4759C7.41152 10.5384 7.33779 10.588 7.25655 10.6219C7.17531 10.6557 7.08817 10.6731 7.00017 10.6731C6.91216 10.6731 6.82502 10.6557 6.74378 10.6219C6.66254 10.588 6.58881 10.5384 6.52683 10.4759L4.52683 8.47594C4.46467 8.41378 4.41537 8.33999 4.38173 8.25877C4.34809 8.17756 4.33077 8.09051 4.33077 8.0026C4.33077 7.82507 4.4013 7.65481 4.52683 7.52927C4.65237 7.40374 4.82263 7.33321 5.00017 7.33321C5.1777 7.33321 5.34796 7.40374 5.4735 7.52927L7.00017 9.0626L10.5268 5.52927C10.6524 5.40374 10.8226 5.33321 11.0002 5.33321C11.1777 5.33321 11.348 5.40374 11.4735 5.52927C11.599 5.65481 11.6696 5.82507 11.6696 6.0026C11.6696 6.18014 11.599 6.3504 11.4735 6.47594Z"
        />
      </svg>
      <div :class="`${classVariableName}-jds-mob-body-xxs ${classVariableName}-jds-color-grey-80`">Certified Buyer</div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { classVariableNameRD } from "../../utils";

export default defineComponent({
  props: {
    color: {
        type: String
    },
    size: {
        type: String,
        default: "xs",
        validator: (value) => ["xs", "s", "m", "l", "xl", "xxl"].includes(value),
    }
  },
  data() {
    return {
        classVariableName: classVariableNameRD
    };
  },
  computed: {
    fillColor() {
      switch (this.kind) {
        case "suceess":
          return "#25AB21"; // Light/Global/Feedback/Success-50
        case "warning":
          return "#F06D0F"; // Light/Global/Feedback/Warning-50
        case "error":
          return "#F50031"; // Light/Global/Feedback/Error-50
        case "primary":
          return "#0C5273"; // Light/Primary/60
        default:
          return "#1ECCB0"; // Light/Sparkle/50
      }
    },
    sizeClass() {
      return `${this.classVariableName}-star-size-${this.size}`;
    }
  }
});
</script>

<style lang="scss" scoped>
  @import "../../assets/AppRD.scss";
</style>

<template>
  <div :class="`${classVariableNameRD}-review-container`">
    <div :class="`${classVariableNameRD}-review-row-top`">
      <div :class="`${classVariableNameRD}-review-row-top-left`">
        <div :class="`${classVariableNameRD}-jds-desk-body-s ${classVariableNameRD}-jds-color-grey-100`"> 
          {{
            isEmpty(review.customerName)
              ? review.createdBy
              : review.customerName
          }}
        </div>
        <div :class="`${classVariableNameRD}-jds-desk-body-xxs ${classVariableNameRD}-jds-color-grey-80`"> 
          {{ review.location ? review.location.city : "Navi Mumbai" }}
        </div>
        <ratingComponent
          :readOnly="true"
          :allowFraction="true"
          :initialRating="review.rating"
          size="s"
          :class="`${classVariableNameRD}-margin-right`"
        />
      </div>

      <div :class="`${classVariableNameRD}-review-row-top-right`">
        <div :class="`${classVariableNameRD}-jds-desk-body-xxs ${classVariableNameRD}-jds-color-grey-80`">
          {{ formatDate(review.createdAt) }}
        </div>
      </div>
    </div>

    <!-- <div :class="`${classVariableNameRD}-review-row-category`">
      Color: Stardust Brown | Size: 256 GB
    </div> -->

    <div v-if="!isEmpty(review.title)" :class="`${classVariableNameRD}-review-row-title`">
      {{ review.title }}
    </div>

    <div v-if="!isEmpty(review.content)" :class="`${classVariableNameRD}-review-row-description`">
      {{ review.content }}
    </div>

    <div
      v-if="!isEmpty(review.videos)"
      id="videos"
      :class="`${classVariableNameRD}-d-flex`"
    >
      <iframe
        v-for="(video, index) of review.videos"
        :key="index"
        :src="video.url"
        :title="video.id"
        :class="`${classVariableNameRD}-imgContainer ${classVariableNameRD}-d-flex`"
      />
    </div>

    <div
      v-if="!isEmpty(review.images)"
      id="images"
      :class="`${classVariableNameRD}-d-flex ${classVariableNameRD}-flex-wrap`"
    >
      <Image
        v-for="(img, index) of review.images"
        :key="index"
        :src="img.url"
        alt="image preview"
        :class="`${classVariableNameRD}-imgContainer ${classVariableNameRD}-d-flex`"
      />
    </div>

    <div :class="`${classVariableNameRD}-review-row-bottom`">
      <CertifiedBuyer v-if="review.verified"/>
      <div v-if="!review.verified"/>

      <fdk-accounts v-slot="accountsData" v-if="vote">
        <div :class="`${classVariableNameRD}-review-row-bottom-actions`" v-if="!accountsData.is_logged_in || review.createdBy !== accountsData.user_data.user._id">
          <div
            v-if="
              !isEmpty(review.title) &&
              !isEmpty(validationConfig) &&
              !isEmpty(validationConfig.upvote) &&
              !validationConfig.upvote.hidden
            "
            :class="`${classVariableNameRD}-vote`"
          >
            <svg
              width="20"
              height="15"
              xmlns="http://www.w3.org/2000/svg"
              :class="`${classVariableNameRD}-cursor-pointer`"
              @click="() => sendVote('UPVOTE', accountsData)"
            >
              <path
                :fill="selectedAction === 'UPVOTE' ? '#2874f0' : '#c2c2c2'"
                d="M9.58.006c-.41.043-.794.32-1.01.728-.277.557-2.334 4.693-2.74 5.1-.41.407-.944.6-1.544.6v8.572h7.5c.45 0 .835-.28 1.007-.665 0 0 2.207-6.234 2.207-6.834 0-.6-.47-1.072-1.07-1.072h-3.216c-.6 0-1.07-.535-1.07-1.07 0-.537.835-3.387 1.006-3.944.17-.557-.107-1.157-.664-1.35-.15-.043-.257-.086-.407-.064zM0 6.434v8.572h2.143V6.434H0z"
                fillRule="evenodd"
              />
            </svg>
            <span :class="`${classVariableNameRD}-vote-text`">
              Helpful ({{ review.upvoteCount }})
            </span>
          </div>

          <div v-if="
              !isEmpty(review.title) &&
              !isEmpty(validationConfig) &&
              !isEmpty(validationConfig.upvote) &&
              !validationConfig.upvote.hidden &&
              !isEmpty(validationConfig.abuseReport) &&
              !validationConfig.abuseReport.hidden
            "
            :class="`${classVariableNameRD}-vertical-divider`"
          />

          <Tooltip
            v-if="
              !isEmpty(review.title) &&
              !isEmpty(validationConfig) &&
              !isEmpty(validationConfig.abuseReport) &&
              !validationConfig.abuseReport.hidden
            "
            label="If you find this content inappropriate and think it should be removed from the Reliance Digital site, you can click on 'Report Abuse'."
          >
          <div :class="`${classVariableNameRD}-report`">
            <svg
              width="16"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
              :class="`${classVariableNameRD}-cursor-pointer`"
              @click="() => {if (accountsData.is_logged_in && !abuseReported) showAbuseModal = true; else sendReportAbuse(accountsData)}"
            >
              <path
                :fill="abuseReported ? '#F50031' : '#B5B5B5'"
                d="M14.4934 12.0015L9.1601 2.66812C9.04381 2.46292 8.87517 2.29224 8.67138 2.1735C8.46759 2.05475 8.23596 1.99219 8.0001 1.99219C7.76424 1.99219 7.5326 2.05475 7.32881 2.1735C7.12503 2.29224 6.95639 2.46292 6.8401 2.66812L1.50676 12.0015C1.38951 12.2045 1.32789 12.4349 1.32813 12.6694C1.32836 12.9039 1.39043 13.1342 1.50808 13.3371C1.62574 13.5399 1.79481 13.7082 1.99824 13.8248C2.20167 13.9415 2.43227 14.0024 2.66676 14.0015H13.3334C13.5679 14.0024 13.7985 13.9415 14.002 13.8248C14.2054 13.7082 14.3745 13.5399 14.4921 13.3371C14.6098 13.1342 14.6718 12.9039 14.6721 12.6694C14.6723 12.4349 14.6107 12.2045 14.4934 12.0015ZM7.33343 4.66812C7.33343 4.49131 7.40367 4.32174 7.52869 4.19671C7.65372 4.07169 7.82329 4.00145 8.0001 4.00145C8.17691 4.00145 8.34648 4.07169 8.4715 4.19671C8.59653 4.32174 8.66676 4.49131 8.66676 4.66812V8.66812C8.66676 8.84493 8.59653 9.0145 8.4715 9.13952C8.34648 9.26455 8.17691 9.33478 8.0001 9.33478C7.82329 9.33478 7.65372 9.26455 7.52869 9.13952C7.40367 9.0145 7.33343 8.84493 7.33343 8.66812V4.66812ZM8.0001 12.6681C7.80232 12.6681 7.60898 12.6095 7.44453 12.4996C7.28008 12.3897 7.15191 12.2335 7.07622 12.0508C7.00053 11.8681 6.98073 11.667 7.01931 11.473C7.0579 11.279 7.15314 11.1009 7.29299 10.961C7.43284 10.8212 7.61103 10.7259 7.80501 10.6873C7.99899 10.6487 8.20005 10.6686 8.38278 10.7442C8.56551 10.8199 8.72169 10.9481 8.83157 11.1125C8.94145 11.277 9.0001 11.4703 9.0001 11.6681C9.0001 11.9333 8.89474 12.1877 8.7072 12.3752C8.51967 12.5628 8.26531 12.6681 8.0001 12.6681Z"
              />
            </svg>
            <span :class="`${classVariableNameRD}-report-text`">
              Report
            </span>
          </div>
          </Tooltip>
        </div>

        <ModalRD
          v-if="showAbuseModal === true"
          importStyle="AppRD"
          ctaTitle="Report"
          :ctaAction="() => handleReportSubmit(accountsData)"
          :ctaActionDisabled="abuseContentError"
          :closeModal="onCloseModal"
        >
          <template #modalTitle>
            <div :class="`${classVariableNameRD}-jds-desk-body-m-bold ${classVariableNameRD}-jds-color-grey-100`">
              Report Abuse
            </div>
          </template>

          <template #default>
            <div :class="`${classVariableNameRD}-modal-body-section`">
              <div :class="`${classVariableNameRD}-modal-sub-section`">
                <div :class="`${classVariableNameRD}-modal-section-header`">
                  If you think this review should be removed from Reliance Digital site, let us know your reason for the same in the comment box below *
                </div>
                <Textarea
                  :value="abuseContent"
                  @updateInput="
                    (event) => {
                      abuseContent = event;
                      abuseContentError = null;
                    }
                  "
                  @hasError="
                    (event) => {
                      abuseContentError = event;
                    }
                  "
                  :maxCharCount="50"
                  :minCharCount="5"
                  placeholder="Report abuse reason"
                />
                <div
                  v-if="abuseContentError"
                  :class="`${classVariableNameRD}-modal-input-error-text`"
                >
                  {{ abuseContentError }}
                </div>
              </div>
            </div>
          </template>
        </ModalRD>
      </fdk-accounts>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import customerServices from "../services/customer-review";
import { isEmpty, classVariableNameRD } from "../utils";
import ratingComponent from "./common/StarRating.vue";
import CertifiedBuyer from "./common/CertifiedBuyer.vue";
import Tooltip from "./common/Tooltip.vue";
import ModalRD from "./common/ModalRD.vue";
import Textarea from "./common/Textarea.vue";

export default defineComponent({
  name: "reviewRDComponent",
  mounted() {},
  components: {
    ratingComponent,
    CertifiedBuyer,
    Tooltip,
    ModalRD,
    Textarea
  },
  data() {
    return {
      selectedAction: undefined,
      abuseReported: undefined,
      showAbuseModal: false,
      abuseContent: "",
      abuseContentError: null,
      classVariableNameRD: classVariableNameRD,
      abuseReportValidations: this.validationConfig && this.validationConfig.abuseReport,
    };
  },
  props: {
    review: {
      type: Object,
      default: {},
    },
    validationConfig: {
      type: Object,
      default: {},
    },
    vote: {
      type: Boolean,
      default: true,
    },
    setAlert: Function,
  },

  methods: {
    isEmpty(val) {
      return isEmpty(val);
    },
    onCloseModal() {
      this.showAbuseModal = false;
      this.abuseContent = "";
      this.abuseContentError = null;
    },
    async sendVote(action, accountsData) {
      if (action === this.selectedAction) {
        this.setAlert("Your feedback has been previously recorded.", "warning");
        return;
      }
      if (!accountsData.is_logged_in) {
        accountsData.openLogin();
        return;
      }

      let data = {
        contentId: this.review.id,
        vote: action,
      };

      await customerServices.voteReview(data).then((data) => {
        let res = data.data;
        if (res.status == 401) {
          accountsData.openLogin();
          return;
        }
        else if (res.resultInfo.statusCode === '200') {
          this.setAlert("Thank you for your feedback.", "success");
          if (action === "DOWNVOTE") {
            this.review.downvoteCount = parseInt(this.review.downvoteCount) + 1;
          }
          else if (action === "UPVOTE") {
            this.review.upvoteCount = parseInt(this.review.upvoteCount) + 1;
          }
          this.selectedAction = action;
        }
        else
          this.setAlert(res.message, "error");
      });
    },

    async handleReportSubmit(accountsData) {
      let contentCheckFail = isEmpty(this.abuseContent);
      if (contentCheckFail) {
        this.abuseContentError = "Please provide abuse report reason";
        return;
      }

      // Check if any word from the list is a substring of the sentence
      const sentenceLowerCase = this.abuseContent.toLowerCase();
      if (this.abuseReportValidations.abusiveWords && this.abuseReportValidations.abusiveWords.some(word => sentenceLowerCase.includes(word.toLowerCase()))) {
        this.abuseContentError = "Please avoid abuse words in the reason";
        return;
      }
      await this.sendReportAbuse(accountsData);
    },

    async sendReportAbuse(accountsData) {
      if (this.abuseReported) {
        this.setAlert("The review is already reported and will be updated once approved.", "warning");
        return;
      }

      if (!accountsData.is_logged_in) {
        accountsData.openLogin();
        return;
      }

      let data = {
        contentId: this.review.id,
        contentOwnerId: this.review.createdBy,
        reporterComment: this.abuseContent
      };

      try {
        await customerServices.reportAbuse(data).then((data) => {
          let res = data.data.resultInfo;
          if (res.statusCode == '401') {
            accountsData.openLogin();
            return;
          }
          if (res.statusCode === '200') {
            this.abuseReported = true;
            this.review.abuseReported = true;
            this.setAlert("Thank you for reporting the review. The review will be updated once report is approved.", "success");
          }
          else
            this.setAlert(res.message, "error");
          this.onCloseModal();
        });
      } catch(error) {
        this.setAlert(error, "error");
        this.onCloseModal();
      }
    },

    formatDate(dateString) {
      var date = new Date(dateString);
      var day = date.getDate();
      var month = date.getMonth()+1;
      var year = date.getFullYear();
      return day + "/" + month + "/" + year;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/AppRD.scss";
</style>

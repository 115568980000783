import axios from "axios";
import CUSTOMER_URLS from "./CustomerURLs.js";

const customerServices = {
  getProductStats(productId) {
    return axios.get(CUSTOMER_URLS.GET_PRODUCT_SUMMARY(productId));
  },
  getAllReviews(productId, query) {
    let url =
      CUSTOMER_URLS.GET_REVIEWS(productId) +
      "?" +
      new URLSearchParams({
        pageNo: query.pageNo,
        pageSize: query.pageSize,
        sortBy: query.sortBy,
        ...(query.rating !== null && query.rating !== undefined && { starRating: query.rating })
      });
    return axios.get(url);
  },
  getProductReviewOfUser(productId) {
    return axios.get(CUSTOMER_URLS.GET_USER_REVIEW(productId));
  },
  postReview(data) {
    return axios.post(CUSTOMER_URLS.ADD_REVIEW(), data);
  },
  editReview(reviewId, data) {
    return axios.put(CUSTOMER_URLS.EDIT_REVIEW(reviewId), data);
  },
  getIfVerifiedPurchase(articleId) {
    return axios.get(CUSTOMER_URLS.GET_IF_VERIFIED_PURCHASED(articleId));
  },
  voteReview(data) {
    return axios.put(CUSTOMER_URLS.UPVOTE_DOWNVOTE_REVIEW(), data);
  },
  reportAbuse(data) {
    return axios.post(CUSTOMER_URLS.REPORT_ABUSE_REVIEW(), data);
  },
  getUserDetails() {
    return axios.get(CUSTOMER_URLS.REVALIDATE_TOKEN());
  },
};
export default customerServices;

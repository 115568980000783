let { url } = require("./urlset");
const attached_path = "jcp-rd";

const CUSTOMER_URLS = {
  GET_REVIEWS: (productId) => {
    let urlPath = `/ext/${attached_path}/application/v1.0/op/review/product/${productId}`;
    return url + urlPath;
  },

  GET_USER_REVIEW: (productId) => {
    let urlPath = `/ext/${attached_path}/application/v1.0/review/user/product/${productId}`;
    return url + urlPath;
  },

  ADD_REVIEW: () => {
    let urlPath = `/ext/${attached_path}/application/v1.0/review/product`;
    return url + urlPath;
  },

  EDIT_REVIEW: (id) => {
    let urlPath = `/ext/${attached_path}/application/v1.0/review/product/${id}`;
    return url + urlPath;
  },

  UPLOAD_FILE: () => {
    let urlPath = `/ext/${attached_path}/application/v1.0/file/multiUpload`;
    return url + urlPath;
  },

  GET_PRODUCT_SUMMARY: (id) => {
    let urlPath = `/ext/${attached_path}/application/v1.0/op/review/product-statistics/${id}`;
    return url + urlPath;
  },

  UPVOTE_DOWNVOTE_REVIEW: () => {
    let urlPath = `/ext/${attached_path}/application/v1.0/review/vote/count`;
    return url + urlPath;
  },

  REPORT_ABUSE_REVIEW: () => {
    let urlPath = `/ext/${attached_path}/application/v1.0/abuse/reportReview`;
    return url + urlPath;
  },

  GET_IF_VERIFIED_PURCHASED: (articleId) => {
    let urlPath = `/ext/${attached_path}/application/v1.0/review/verifyPurchase/${articleId}`;
    return url + urlPath;
  },

  GET_REVIEW_VALIDATION_CONFIG: () => {
    let urlPath = `/ext/${attached_path}/application/v1.0/op/config/review-validation`;
    return url + urlPath;
  },

  REVALIDATE_TOKEN: () => {
    let urlPath = `/ext/${attached_path}/application/v1.0/user/details`;
    return url + urlPath;
  },
};

export default CUSTOMER_URLS;

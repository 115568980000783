<template>
  <div
    :class="`${classVariableName}-popup-container ${classVariableName}-popup-container-desktop ${
      showAlert ? 'show' : 'hide'
    }`"
  >
    <div :class="`${classVariableName}-popup-content`">
      <!-- error - cross - red -->
      <svg
        v-if="type === 'error'"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="20" fill="white"/>
        <path d="M24 4C20.0444 4 16.1776 5.17298 12.8886 7.37061C9.59962 9.56824 7.03617 12.6918 5.52242 16.3463C4.00867 20.0009 3.6126 24.0222 4.3843 27.9018C5.15601 31.7814 7.06082 35.3451 9.85787 38.1421C12.6549 40.9392 16.2186 42.844 20.0982 43.6157C23.9778 44.3874 27.9992 43.9913 31.6537 42.4776C35.3082 40.9638 38.4318 38.4004 40.6294 35.1114C42.827 31.8224 44 27.9556 44 24C44 21.3736 43.4827 18.7728 42.4776 16.3463C41.4725 13.9198 39.9993 11.715 38.1421 9.85786C36.285 8.00069 34.0802 6.5275 31.6537 5.52241C29.2272 4.51732 26.6264 4 24 4V4ZM31.42 28.58C31.6075 28.7659 31.7563 28.9871 31.8578 29.2308C31.9593 29.4746 32.0116 29.736 32.0116 30C32.0116 30.264 31.9593 30.5254 31.8578 30.7692C31.7563 31.0129 31.6075 31.2341 31.42 31.42C31.2341 31.6075 31.0129 31.7562 30.7692 31.8578C30.5254 31.9593 30.264 32.0116 30 32.0116C29.736 32.0116 29.4746 31.9593 29.2309 31.8578C28.9871 31.7562 28.7659 31.6075 28.58 31.42L24 26.82L19.42 31.42C19.2341 31.6075 19.0129 31.7562 18.7692 31.8578C18.5254 31.9593 18.264 32.0116 18 32.0116C17.736 32.0116 17.4746 31.9593 17.2309 31.8578C16.9871 31.7562 16.7659 31.6075 16.58 31.42C16.3926 31.2341 16.2438 31.0129 16.1422 30.7692C16.0407 30.5254 15.9884 30.264 15.9884 30C15.9884 29.736 16.0407 29.4746 16.1422 29.2308C16.2438 28.9871 16.3926 28.7659 16.58 28.58L21.18 24L16.58 19.42C16.2034 19.0434 15.9918 18.5326 15.9918 18C15.9918 17.4674 16.2034 16.9566 16.58 16.58C16.9566 16.2034 17.4674 15.9918 18 15.9918C18.5326 15.9918 19.0434 16.2034 19.42 16.58L24 21.18L28.58 16.58C28.9566 16.2034 29.4674 15.9918 30 15.9918C30.5326 15.9918 31.0434 16.2034 31.42 16.58C31.7966 16.9566 32.0082 17.4674 32.0082 18C32.0082 18.5326 31.7966 19.0434 31.42 19.42L26.82 24L31.42 28.58Z" fill="#F50031"/>
      </svg>

      <!-- warning - info - orange-->
      <svg
        v-if="type === 'warning'"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="20" fill="white"/>
        <path
          d="M24 4C20.0444 4 16.1776 5.17298 12.8886 7.37061C9.59962 9.56824 7.03617 12.6918 5.52242 16.3463C4.00867 20.0009 3.6126 24.0222 4.3843 27.9018C5.15601 31.7814 7.06082 35.3451 9.85787 38.1421C12.6549 40.9392 16.2186 42.844 20.0982 43.6157C23.9778 44.3874 27.9992 43.9913 31.6537 42.4776C35.3082 40.9638 38.4318 38.4004 40.6294 35.1114C42.827 31.8224 44 27.9556 44 24C44 21.3736 43.4827 18.7728 42.4776 16.3463C41.4725 13.9198 39.9993 11.715 38.1421 9.85786C36.285 8.00069 34.0802 6.5275 31.6537 5.52241C29.2272 4.51732 26.6264 4 24 4ZM22 13C22 12.4696 22.2107 11.9609 22.5858 11.5858C22.9609 11.2107 23.4696 11 24 11C24.5304 11 25.0391 11.2107 25.4142 11.5858C25.7893 11.9609 26 12.4696 26 13V25C26 25.5304 25.7893 26.0391 25.4142 26.4142C25.0391 26.7893 24.5304 27 24 27C23.4696 27 22.9609 26.7893 22.5858 26.4142C22.2107 26.0391 22 25.5304 22 25V13ZM24 37C23.4067 37 22.8266 36.8241 22.3333 36.4944C21.84 36.1648 21.4554 35.6962 21.2284 35.148C21.0013 34.5999 20.9419 33.9967 21.0577 33.4147C21.1734 32.8328 21.4591 32.2982 21.8787 31.8787C22.2982 31.4591 22.8328 31.1734 23.4147 31.0576C23.9967 30.9419 24.5999 31.0013 25.1481 31.2284C25.6962 31.4554 26.1648 31.8399 26.4944 32.3333C26.8241 32.8266 27 33.4067 27 34C27 34.7956 26.6839 35.5587 26.1213 36.1213C25.5587 36.6839 24.7957 37 24 37Z"
          fill="#F06D0F"
        />
      </svg>

      <!-- success - tick - green-->
      <svg
        v-if="type === 'success'"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="20" fill="white"/>
        <path
          d="M24 4C20.0444 4 16.1776 5.17298 12.8886 7.37061C9.59962 9.56824 7.03617 12.6918 5.52242 16.3463C4.00867 20.0009 3.6126 24.0222 4.3843 27.9018C5.15601 31.7814 7.06082 35.3451 9.85787 38.1421C12.6549 40.9392 16.2186 42.844 20.0982 43.6157C23.9778 44.3874 27.9992 43.9913 31.6537 42.4776C35.3082 40.9638 38.4318 38.4004 40.6294 35.1114C42.827 31.8224 44 27.9556 44 24C44 21.3736 43.4827 18.7728 42.4776 16.3463C41.4725 13.9198 39.9993 11.715 38.1421 9.85786C36.285 8.00069 34.0802 6.5275 31.6537 5.52241C29.2272 4.51732 26.6264 4 24 4V4ZM34.42 19.42L22.42 31.42C22.2341 31.6075 22.0129 31.7562 21.7692 31.8578C21.5254 31.9593 21.264 32.0116 21 32.0116C20.736 32.0116 20.4746 31.9593 20.2309 31.8578C19.9871 31.7562 19.7659 31.6075 19.58 31.42L13.58 25.42C13.3935 25.2335 13.2456 25.0121 13.1447 24.7685C13.0438 24.5249 12.9918 24.2637 12.9918 24C12.9918 23.4674 13.2034 22.9566 13.58 22.58C13.9566 22.2034 14.4674 21.9918 15 21.9918C15.5326 21.9918 16.0434 22.2034 16.42 22.58L21 27.18L31.58 16.58C31.9566 16.2034 32.4674 15.9918 33 15.9918C33.5326 15.9918 34.0434 16.2034 34.42 16.58C34.7966 16.9566 35.0082 17.4674 35.0082 18C35.0082 18.5326 34.7966 19.0434 34.42 19.42V19.42Z"
          fill="#25AB21"
        />
      </svg>

      <div :class="`${classVariableName}-popup-message`">{{ message }}</div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import { classVariableName } from "../../utils";

export default defineComponent({
  name: "alertComponent",
  setup() {},
  data() {
    return {
      classVariableName: classVariableName,
    };
  },
  methods: {},
  props: {
    type: {
      type: String,
      default: "success",
    },
    message: {
      type: String,
      default: "",
    },
    showAlert: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/App.scss";
</style>

<template>
  <div :class="`${classVariableNameRD}-rating-stats-container`">
    <div :class="`${classVariableNameRD}-rating-content`">
      <div :class="`${classVariableNameRD}-flex-row ${classVariableNameRD}-align-items-flex-end`">
        <span :class="`${classVariableNameRD}-jds-desk-body-m-bold ${classVariableNameRD}-jds-color-grey-100`">
          {{ averageRating }}
        </span>
        <span :class="`${classVariableNameRD}-jds-desk-body-xxs-bold ${classVariableNameRD}-jds-color-grey-80`">
          / {{ maximumRating }}
        </span>
      </div>
      <div :class="`${classVariableNameRD}-average-rating-star-icon`">
        <ratingComponent
          :readOnly="true"
          :initialRating="averageRating"
          size="s"
        />
      </div>
    </div>

    <div :class="`${classVariableNameRD}-progress-container`">
      <div
        v-for="key of ratingKeys"
        :key="key"
        :class="`${classVariableNameRD}-progress-row`"
        @click="$emit('update:ratingFilter', key)"
      >
        <div :class="`${classVariableNameRD}-rating-rate`">
          <div :class="`${classVariableNameRD}-rating-text`">{{ key }}</div>
          <Star :size="xs" :kind="colorFillKind(key)" :pointy="true" />
        </div>

        <div
          :class="`${classVariableNameRD}-rating-bar`"
        >
          <progressBarRDComponent
            :kind="colorFillKind(key)"
            :percentage="getRatingStats(key)"
          />
        </div>
        <div :class="`${classVariableNameRD}-rating-count`">
          {{ ratingStats[`${key}`] ? ratingStats[`${key}`] : 0 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { isEmpty, classVariableNameRD } from "../utils";
import progressBarRDComponent from "./common/ProgressBarRD.vue";
import ratingComponent from "./common/StarRating.vue";
import Star from "./common/Star.vue";

const ratingKeys = [5, 4, 3, 2, 1];

export default defineComponent({
  name: "ratingStatsRDComponent",
  setup() {},
  components: {
    progressBarRDComponent,
    ratingComponent,
    Star
  },
  data() {
    return {
      ratingKeys: ratingKeys,
      classVariableNameRD: classVariableNameRD,
    };
  },
  props: {
    averageRating: {
      type: Number,
      default: 0,
    },
    totalReviews: {
      type: Number,
      default: 0,
    },
    ratingStats: {
      type: Object,
      default: {},
    },
    maximumRating: {
      type: Number,
      default: 5,
    },
    ratingFilter: {
      type: Number,
      default: null
    }
  },
  methods: {
    isEmpty(val) {
      return isEmpty(val);
    },

    getRatingStats(key) {
      let value =
        isEmpty(this.ratingStats) || isEmpty(this.ratingStats[`${key}`])
          ? 0
          : Math.round((this.ratingStats[`${key}`] / this.totalReviews) * 100);
      return value;
    },

    colorFillKind(idx) {
      return idx >= 3 ? "success" : idx == 2 ? "warning" : "error";
    }
  },
});
</script>
<style lang="scss" scoped>
@import "../assets/AppRD.scss";
</style>

<template>
  <div :class="`${classVariableNameRD}-progress`">
    <div
      :class="`${colorClass}`"
      :style="{width: `${percentage}% !important`}"
    ></div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { classVariableNameRD } from "../../utils";

export default defineComponent({
  name: "progressBarRDComponent",
  props: {
    percentage: {
      type: Number,
      default: 0,
      validator: (value) => value >= 0 && value <= 100,
    },
    kind: {
      type: String,
      default: "primary",
      validator: (value) =>
        ["primary", "success", "warning", "error"].includes(value),
    },
  },
  data() {
    return {
      classVariableNameRD: classVariableNameRD,
    };
  },
  computed: {
    colorClass() {
      return `${this.classVariableNameRD}-bg-${this.kind}`;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/AppRD.scss";
</style>

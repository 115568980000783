<template>
  <div
    :class="`${classVariableName}-rating-container ${readOnly ? '' : `${classVariableName}-cursor-pointer`}`"
    style="display: flex;"
  >
    <div
      v-for="star in maxStars"
      :key="star"
      @mouseenter="!readOnly && setHoverValue(star)"
      @mouseleave="clearHoverValue"
      @click="!readOnly && setSelectedValue(star)"
      :aria-disabled="readOnly"
    >
      <Star
        :size="size"
        :kind="starFillKind(star)"
        :fillPercent="starFillKind(star) === 'partial' ? starFillPercent() : null"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Star from "./Star.vue";
import { classVariableName } from "../../utils";

export default defineComponent({
  name: "ratingComponent",
  components: {
    Star,
  },
  props: {
    maxStars: {
      type: Number,
      default: 5, // Default number of stars
    },
    initialRating: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "m",
      validator: (value) => ["xs", "s", "m", "l", "xl", "xxl"].includes(value),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    allowFraction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoverValue: 0, // Tracks the hovered star rating
      selectedValue: this.initialRating, // Tracks the selected star rating
      classVariableName: classVariableName,
    };
  },
  computed: {
    validInitialRating() {
      return (
        !this.initialRating ||
        (this.initialRating >= 1 && this.initialRating <= this.maxStars)
      );
    },
  },
  methods: {
    starFillKind(star) {
      return !this.initialRating
        ? star <= this.hoverValue
          ? "filled"
          : "light-grey"
        : star <= this.initialRating
        ? "filled"
        : star == Math.ceil(this.initialRating)
        ? "partial"
        : "light-grey";
    },
    starFillPercent() {
      return (this.initialRating - Math.floor(this.initialRating)) * 100;
    },
    setHoverValue(star) {
      this.hoverValue = star;
    },
    setSelectedValue(star) {
      this.selectedValue = star;
      this.$emit("updateRating", this.selectedValue);
    },
    clearHoverValue() {
      this.hoverValue = 0;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/App.scss";
</style>

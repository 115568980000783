export const getCommonHeaderOptions = (defaultHeader = {}) => {
  return {
    headers: {
      "Content-Type": "application/json",
      ...defaultHeader,
    },
  };
};

export const isEmpty = (value) => {
  return value === null || value === undefined || value.length === 0;
};

export const setStorageWithExpiry = (key, value, ttl) => {
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getStorageWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const emitEvent = (event, page, product) => {
  
  let loggedIn = window.FPI.state.user._data;
  let userDetails = null;
  if (!isEmpty(Object.keys(loggedIn))) {
    userDetails = loggedIn.user;
  }
  let data = {'event':event,
    'product_name': product.productName,
    'product_category': !isEmpty(product.categoryL2) ? product.categoryL2.name : undefined,
    'product_id': product.alternateCode1,
    'platform_name': isMobile ? 'MOBILE' : 'DESKTOP',
    "review_source": page === 'PDP' ? 'product_page' : page === 'orderDetails' ? 'order_page' : 'thank_you',
    'User_ID': !isEmpty(userDetails)?userDetails.user_id : undefined,
    'is_loggedin': !isEmpty(userDetails) ? 'loggedin' : 'non_loggedin'
  }
  window.dataLayer.push(data);
}

export const isMobile = window.innerWidth <= 430;
export const FEEDBACK_VALIDATION_CONFIG_KEY = "customer_review_validation_config";
export const FEEDBACK_VALIDATION_CONFIG_TTL = 86400000;
export const classVariableName = "feedback-service";
export const classVariableNameRD = "rd-feedback-service";

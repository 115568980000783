<template>
  <div v-if="isMounted">
    <div :class="`${classVariableNameRD}-custom-container`">
      <Accordion>
        <template v-slot:header>
          <span :class="`${classVariableNameRD}-rr-heading-1`">
            <span :class="`${classVariableNameRD}-rr-heading-1`">
              Reviews & Ratings
            </span>
            <span v-if="ratingsCount" :class="`${classVariableNameRD}-rr-heading-2`">
                .  ({{ratingsCount}} Ratings & {{reviewsCount}} Reviews)
            </span>
          </span>
        </template>
        <template v-slot:content>
          <div>
          <div :class="`${classVariableNameRD}-col-md-8`">
            <div
              id="content"
              :class="`${classVariableNameRD}-jds-desk-body-xs ${classVariableNameRD}-jds-color-grey-80`"
            >
              {{ productName }}
            </div>

            <div :class="`${classVariableNameRD}-rating-overall-container`">
              <div :class="ratingsCount > 0 ? `${classVariableNameRD}-rating-overall-header` : `${classVariableNameRD}-rating-overall-header-no-rating`">
                <span :class="`${classVariableNameRD}-jds-desk-body-xs-bold ${classVariableNameRD}-jds-color-grey-100 ${classVariableNameRD}-write-review-button`">
                  {{ ratingsCount > 0 ? 'Overall Rating' : 'Be first one to review' }}
                </span>
                <fdk-accounts v-slot="accountsData">
                  <div
                    :class="`${classVariableNameRD}-review-form-block`"
                    @click="openAddReviewPopup(accountsData)"
                  >
                    <button
                      :class="ratingsCount > 0 ?
                        `${classVariableNameRD}-button-tertiary ${classVariableNameRD}-cursor-pointer ${classVariableNameRD}-write-review-button` :
                        `${classVariableNameRD}-button-secondary ${classVariableNameRD}-cursor-pointer ${classVariableNameRD}-write-review-button`
                      "
                      :disabled="isEmpty(showAlert) ? false : showAlert"
                    >
                      Write a Review
                    </button>
                  </div>
                </fdk-accounts>
              </div>
              <ratingStatsRDComponent
                v-if="ratingsCount>0"
                :averageRating="avgRating"
                :totalReviews="ratingsCount"
                :ratingStats="ratingsCountDetails"
                :maximumRating="maxRating"
                :ratingFilter.sync="ratingFilter"
              />
            </div>
          </div>

          <div :class="`${classVariableNameRD}-col-md-8 ${classVariableNameRD}-col-sm-12`">
            <div :class="`${classVariableNameRD}-review-list-section`">
              <div v-if="(reviewsCount > 0 && ratingsCount > 0) || ratingFilter" :class="`${classVariableNameRD}-border-bottom`"/>
              <div v-if="ratingFilter" :class="`${classVariableNameRD}-review-list-filter-title ${classVariableNameRD}-review-row-description`">
                <div :class="`${classVariableNameRD}-jds-desk-body-xs-bold ${classVariableNameRD}-jds-color-grey-100`">{{ratingFilter}} star</div>
                <div v-if="!reviewsCountDetails[ratingFilter] && !ratingsCountDetails[ratingFilter]"> Ratings not present yet </div>
                <div v-if="!reviewsCountDetails[ratingFilter] && ratingsCountDetails[ratingFilter]"> ({{ratingsCountDetails[ratingFilter]}} ratings) </div>
                <div v-if="reviewsCountDetails[ratingFilter] && ratingsCountDetails[ratingFilter]"> ({{ratingsCountDetails[ratingFilter]}} ratings and {{reviewsCountDetails[ratingFilter]}} reviews) </div>
                <button
                  :class="`${classVariableNameRD}-button-tertiary ${classVariableNameRD}-cursor-pointer`"
                  @click="ratingFilter=null"
                >
                  Clear Filter
                </button>
              </div>
              <div
                v-if="
                  ratingsCount > 0 &&
                  !isEmpty(validationConfig) &&
                  reviews.length > 0
                "
              >
                <div v-for="(item, index) in reviews" :key="item.id">
                  <reviewRDComponent
                    :review="item"
                    :validationConfig="validationConfig"
                    :vote="isEmpty(userCurrentReview) ? true : item.id !== userCurrentReview.id"
                    :setAlert="(message, type) => toast(message, type)"
                  />
                  <div v-if="index !== reviews.length - 1" :class="`${classVariableNameRD}-border-bottom`"/>
                </div>
              </div>
            </div>

            <div
              v-if="(pageNo > 0 || hasNext) && ratingsCount > 0"
              :class="`${classVariableNameRD}-review-buttons-container`"
            >
              <button
                :disabled="pageNo === 0"
                :class="`${classVariableNameRD}-review-button-noborder`"
                @click="() => {
                  pageNo = pageNo - 1;
                  getReviews(sortBy, pageNo, ratingFilter);
                }"
              >
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17" fill="none">
                    <path
                      d="M8.99994 17.0002C8.86833 17.0009 8.73787 16.9757 8.61603 16.926C8.49419 16.8762 8.38338 16.8029 8.28994 16.7102L1.28994 9.71019C1.19621 9.61722 1.12182 9.50662 1.07105 9.38476C1.02028 9.2629 0.994141 9.1322 0.994141 9.00019C0.994141 8.86817 1.02028 8.73747 1.07105 8.61561C1.12182 8.49375 1.19621 8.38315 1.28994 8.29019L8.28994 1.29019C8.38318 1.19695 8.49387 1.12299 8.61569 1.07253C8.73751 1.02207 8.86808 0.996094 8.99994 0.996094C9.1318 0.996094 9.26237 1.02207 9.38419 1.07253C9.50601 1.12299 9.6167 1.19695 9.70994 1.29019C9.80318 1.38342 9.87714 1.49411 9.9276 1.61594C9.97806 1.73776 10.004 1.86833 10.004 2.00019C10.004 2.13204 9.97806 2.26261 9.9276 2.38443C9.87714 2.50626 9.80318 2.61695 9.70994 2.71019L3.40994 9.00019L9.70994 15.2902C9.80367 15.3831 9.87806 15.4937 9.92883 15.6156C9.9796 15.7375 10.0057 15.8682 10.0057 16.0002C10.0057 16.1322 9.9796 16.2629 9.92883 16.3848C9.87806 16.5066 9.80367 16.6172 9.70994 16.7102C9.6165 16.8029 9.50568 16.8762 9.38385 16.926C9.26201 16.9757 9.13155 17.0009 8.99994 17.0002Z"
                      fill="#000093"
                    />
                  </svg>
                </div>
              </button>
              <button
                :disabled="!hasNext"
                :class="`${classVariableNameRD}-review-button-noborder`"
                @click="() => {
                  pageNo = pageNo + 1;
                  getReviews(sortBy, pageNo, ratingFilter);
                }"
              >
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17" fill="none" style="transform: rotate(-180deg)">
                    <path
                      d="M8.99994 17.0002C8.86833 17.0009 8.73787 16.9757 8.61603 16.926C8.49419 16.8762 8.38338 16.8029 8.28994 16.7102L1.28994 9.71019C1.19621 9.61722 1.12182 9.50662 1.07105 9.38476C1.02028 9.2629 0.994141 9.1322 0.994141 9.00019C0.994141 8.86817 1.02028 8.73747 1.07105 8.61561C1.12182 8.49375 1.19621 8.38315 1.28994 8.29019L8.28994 1.29019C8.38318 1.19695 8.49387 1.12299 8.61569 1.07253C8.73751 1.02207 8.86808 0.996094 8.99994 0.996094C9.1318 0.996094 9.26237 1.02207 9.38419 1.07253C9.50601 1.12299 9.6167 1.19695 9.70994 1.29019C9.80318 1.38342 9.87714 1.49411 9.9276 1.61594C9.97806 1.73776 10.004 1.86833 10.004 2.00019C10.004 2.13204 9.97806 2.26261 9.9276 2.38443C9.87714 2.50626 9.80318 2.61695 9.70994 2.71019L3.40994 9.00019L9.70994 15.2902C9.80367 15.3831 9.87806 15.4937 9.92883 15.6156C9.9796 15.7375 10.0057 15.8682 10.0057 16.0002C10.0057 16.1322 9.9796 16.2629 9.92883 16.3848C9.87806 16.5066 9.80367 16.6172 9.70994 16.7102C9.6165 16.8029 9.50568 16.8762 9.38385 16.926C9.26201 16.9757 9.13155 17.0009 8.99994 17.0002Z"
                      fill="#000093"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          </div>
        </template>
      </Accordion>

      <addReviewRDComponent
        v-if="addReview && !isEmpty(validationConfig)"
        :prdId="productId"
        :varientId="alternateCode1"
        :mstarId="alternateCode2"
        :prdlink="productURL"
        :prdImgURL="productImage"
        :prdName="productName"
        :categoryL1Id="categoryL1.id"
        :categoryL2Id="categoryL2.id"
        :categoryL3Id="categoryL3.id"
        :categoryL4Id="categoryL4.id"
        :categoryL1Name="categoryL1.name"
        :categoryL2Name="categoryL2.name"
        :categoryL3Name="categoryL3.name"
        :categoryL4Name="categoryL4.name"
        :reload="init"
        :onCloseAndRefresh="init"
        :onClose="onClose"
        :showAlert="isEmpty(showAlert) ? false : showAlert"
        :setAlert="(message, type) => this.toast(message, type)"
        :initialRating="rating"
        :validationConfig="validationConfig"
        ingressPoint="PDP"
        :location="location"
      />
    </div>

    <alertComponent
      v-if="!isEmpty(showAlert)"
      :type="alertType"
      :message="alertMessage"
      :showAlert="showAlert"
    />
  </div>
</template>
<script>
import Accordion from "./components/common/Accordion.vue";
import addReviewRDComponent from "./components/AddReviewRD.vue";
import ratingStatsRDComponent from "./components/RatingStatsRD.vue";
import reviewRDComponent from "./components/ReviewRD.vue";
import alertComponent from "./components/common/Alert.vue";
import {
  getStorageWithExpiry,
  isEmpty,
  setStorageWithExpiry,
  FEEDBACK_VALIDATION_CONFIG_KEY,
  FEEDBACK_VALIDATION_CONFIG_TTL,
  classVariableNameRD,
  isMobile,
} from "./utils.js";
import customerServices from "./services/customer-review.js";
import configuration from "./services/configuration.js";

export default {
  name: "ReviewsRDComponent",
  components: {
    addReviewRDComponent,
    ratingStatsRDComponent,
    reviewRDComponent,
    alertComponent,
    Accordion,
  },
  computed: {},
  data() {
    return {
      // review
      validationConfig: null,
      pageNo: 0,
      sortBy: "MOST_RECENT",
      ratingFilter: null,
      hasNext: false,
      reviews: [],
      addReview: false,
      avgRating: null,
      maxRating: 0,
      ratingsCountDetails: null,
      reviewsCountDetails: null,
      ratingsCount: 0,
      reviewsCount: 0,
      showAlert: null,
      alertType: "success",
      alertMessage: null,
      userCurrentReview: null,
      userReviewed: false,
      isPendingReview: false,
      rating: 0,
      verifyPurchaseMessage: {
        DEFAULT_REVIEW_CONSUMED: 'You have already reviewed this product. To review again, kindly purchase the product.',
        NOT_PURCHASED: "Sorry! You are not allowed to review this product since you haven't bought it on Reliance Digital.",
        REVIEW_EXHAUSTED: "You have maxed out review submissions for this product since the last purchase."
      },
      location: null,

      // product
      productId: null,
      alternateCode1: null,
      alternateCode2: null,
      categoryL1: {
        id: null,
        name: null,
      },
      categoryL2: {
        id: null,
        name: null,
      },
      categoryL3: {
        id: null,
        name: null,
      },
      categoryL4: {
        id: null,
        name: null,
      },
      productURL: null,

      baseURL: null,
      productName: null,
      productImage: null,
      isMounted: false,
      classVariableNameRD: classVariableNameRD,
    };
  },
  mounted() {
    console.log("here in PDP: ", this.bindingData);
    this.location = this.bindingData;
    this.baseURL = window.location.origin;
    this.isMounted = true;
    window.FPI.state.product.subscribe(this.getProducts.bind(this));
  },
  destroy() {
    window.FPI.state.product.unsubscribe(this.getProducts.bind(this));
  },
  props: {
    bindingData: Object,
  },
  watch: {
    ratingFilter(newRatingFilter) {
      // This function will be triggered when ratingFilter changes in the child component
      this.getReviews(this.sortBy, this.pageNo, newRatingFilter);
    }
  },
  methods: {
    isEmpty(val) {
      return isEmpty(val);
    },
    async openAddReviewPopup(accountsData) {
      if (!accountsData.is_logged_in) {
        let event = {
          "event": "log_in_click",
          "platform_name": isMobile?'MOBILE':'DESKTOP',
          "login_intent": "review_cta",
          "is_loggedin": "non_loggedin"
        }
        window.dataLayer.push(event);
        accountsData.openLogin();
      } else if (await this.getIfAllowedToReview()) {
        this.addReview = true;
      }
    },

    async getIfAllowedToReview() {
      try {
        let response = await customerServices.getIfVerifiedPurchase(this.alternateCode1);
        let resp = response.data;
        if (resp.resultInfo.status === "SUCCESS") {
          if (resp.data.reviewAllowed) {
            return true;
          } else {
            let messageKey = resp.data.message;
            this.toast(this.verifyPurchaseMessage[messageKey], "warning");
            return false;
          }
        }
      } catch (error) {
        this.toast(error, "warning");
        return false;
      }
    },

    async getReviews(sort, PAGE_NO, ratingFilter) {
      return await customerServices
        .getAllReviews(this.productId, {
          pageNo: PAGE_NO,
          pageSize: 5,
          sortBy: sort,
          rating: ratingFilter
        })
        .then((data) => {
          let res = data.data;
          if (res.resultInfo.status === "SUCCESS") {
            this.reviews = res.data.reviews;
            this.hasNext = res.data.page.hasNext;
          } else {
            this.toast(res.resultInfo.message, "error");
          }
        });
    },
    async getUserReview() {
      return await customerServices
        .getProductReviewOfUser(this.productId)
        .then(async (data) => {
          let res = data.data;
          if (res.resultInfo.status === "SUCCESS") {
            if (!isEmpty(res.data)) {
              var isPendingReview = await this.checkIfReviewInPendingState(
                res.data
              );
              if (isPendingReview) this.userCurrentReview = res.data["PENDING"];
              else this.userCurrentReview = res.data["APPROVED"];
              this.userReviewed = true;
              this.isPendingReview = isPendingReview;
            }
          }
        })
        .catch(() => {
          this.rating = 0;
        });
    },
    async getReviewValidationConfig() {
      var config = getStorageWithExpiry(FEEDBACK_VALIDATION_CONFIG_KEY);
      if (!isEmpty(config)) {
        this.validationConfig = config;
        this.maxRating = config.rating.max;
        return;
      }
      return await configuration
        .getValidationConfig()
        .then((data) => {
          let res = data.data;
          if (res.resultInfo.status === "SUCCESS") {
            config = res.data.data;
            this.validationConfig = config;
            this.maxRating = config.rating.max;
            setStorageWithExpiry(
              FEEDBACK_VALIDATION_CONFIG_KEY,
              config,
              FEEDBACK_VALIDATION_CONFIG_TTL
            );
          } else {
            this.toast(res.resultInfo.message, "error");
          }
        })
        .catch((error) => console.error(error));
    },
    async checkIfReviewInPendingState(reviewMap) {
      return !isEmpty(reviewMap["PENDING"]);
    },
    async toast(message, type) {
      this.alertMessage = message;
      this.showAlert = true;
      this.alertType = type;
      setTimeout(() => {
        this.showAlert = false;
      }, 3000);
    },
    async getProductSummary() {
      return await customerServices
        .getProductStats(this.productId)
        .then((data) => {
          let res = data.data;
          if (res.resultInfo.status !== "SUCCESS") {
            this.toast(res.resultInfo.message, "error");
          } else if (
            res.resultInfo.status === "SUCCESS" &&
            !isEmpty(res.data)
          ) {
            this.avgRating = res.data.averageRating;
            this.ratingsCountDetails = res.data.ratingsCountDetails;
            this.reviewsCountDetails = res.data.reviewsCountDetails;
            this.ratingsCount = res.data.ratingsCount;
            this.reviewsCount = res.data.reviewsCount;
          }
        })
        .catch((error) => console.error(error));
    },

    async getProducts(product) {
      this.productId = product.product.item_code;
      this.alternateCode1 = product.product.item_code;
      this.alternateCode2 = product.product.uid;
      if (!isEmpty(product.product.category_map)) {
        let category1 = product.product.category_map.l1;
        let category2 = product.product.category_map.l2;
        let category3 = product.product.category_map.l3;
        let category4 = product.product.category_map.l4;

        if (!isEmpty(category1)) {
          this.categoryL1 = {
            ...category1,
          };
        }
        if (!isEmpty(category2)) {
          this.categoryL2 = {
            ...category2,
          };
        }
        if (!isEmpty(category3)) {
          this.categoryL3 = {
            ...category3,
          };
        }
        if (!isEmpty(category4)) {
          this.categoryL4 = {
            ...category4,
          };
        }
      }
      this.productName = product.product.name;
      this.productImage = product.product.medias[0].url;
      this.productURL =
      this.baseURL + "/" + product.product.type + "/" + product.product.slug;
      this.init();
    },
    onClose() {
      this.rating = 0;
      this.addReview = false;
    },
    init() {
      this.rating = 0;
      this.addReview = false;
      this.getReviews(this.sortBy, this.pageNo, this.ratingFilter);
      this.getReviewValidationConfig();
      this.getProductSummary();
      this.getUserReview();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/AppRD.scss";
</style>

import { render, staticRenderFns } from "./AverageRatingRD.vue?vue&type=template&id=6a125585&scoped=true"
import script from "./AverageRatingRD.vue?vue&type=script&lang=js"
export * from "./AverageRatingRD.vue?vue&type=script&lang=js"
import style0 from "./AverageRatingRD.vue?vue&type=style&index=0&id=6a125585&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a125585",
  null
  
)

export default component.exports